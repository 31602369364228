/* You can add global styles to this file, and also import other style files */
@import "./zorro.css"; 
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@ng-select/ng-select/themes/default.theme.css";
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import '../node_modules/ngx-guided-tour/css/guided-tour-base-theme.css';

#sd{

}

html{
  scrollbar-width: none;
}

body{
  overflow-y: visible !important;
}
/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
  }
  .toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
  }
  .toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .toast-top-left {
    top: 12px;
    left: 12px;
  }
  .toast-top-right {
    top: 12px;
    right: 12px;
  }
  .toast-bottom-right {
    right: 12px;
    bottom: 12px;
  }
  .toast-bottom-left {
    bottom: 12px;
    left: 12px;
  }
  
  /* toast styles */
  .toast-title {
    font-weight: bold;
  }
  .toast-message {
    word-wrap: break-word;
  }
  .toast-message a,
  .toast-message label {
    color: #FFFFFF;
  }
  .toast-message a:hover {
    color: #CCCCCC;
    text-decoration: none;
  }
  .toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
  }
  .toast-close-button:hover,
  .toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
  }
  /*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
  button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
  }
  .toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
  }
  .toast-container * {
    box-sizing: border-box;
  }
  .toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
  }
  .toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
  .toast-info {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
  .toast-error {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
  .toast-success {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
  }
  /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
  .toast-warning {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
  }
  .toast-container.toast-top-center .ngx-toastr,
  .toast-container.toast-bottom-center .ngx-toastr {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .toast-container.toast-top-full-width .ngx-toastr,
  .toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
  }
  .ngx-toastr {
    background-color: #030303;
    pointer-events: auto;
  }
  .toast-success {
    background-color: #51A351;
  }
  .toast-error {
    background-color: #BD362F;
  }
  .toast-info {
    background-color: #2F96B4;
  }
  .toast-warning {
    background-color: #F89406;
  }
  .toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #000000;
    opacity: 0.4;
  }
  /* Responsive Design */
  @media all and (max-width: 240px) {
    .toast-container .ngx-toastr.div {
      padding: 8px 8px 8px 50px;
      width: 11em;
    }
    .toast-container .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
  @media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .ngx-toastr.div {
      padding: 8px 8px 8px 50px;
      width: 18em;
    }
    .toast-container .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
  }
  @media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .ngx-toastr.div {
      padding: 15px 15px 15px 50px;
      width: 25em;
    }
  }

  * {
    /* font-family: 'Anton'; */
  } 
.body{
    font-family: 'Anton';
    font-size: 22px;
    overflow-x: hidden;
    width: 100%;
}
.heroSectionMain .slick-next {
  display: none !important;
}
.heroSectionMain .slick-prev {
  display: none !important;
}
.slick-list{
    height: 100% !important;
    width: 100%;
}
 .slick-track{
    height: 100% ;
    width: 100%;
}
.slick-dots {
    display: flex;
    justify-content: center;
     z-index: 5;
    margin: 0;
    padding: 1rem 0;
     
    list-style-type: none;
     
    :is(li) {
        margin: 0 0.25rem !important;
        background-color: #383c44;
        height: 16px !important ;
        width: 16px !important ;
        align-self: center;

      }
     
      :is(button) {
        display: flex;
        width: 1rem;
        height: 1rem;
        padding: 0;
         
        border: none;
        /* border-radius: 100%; */
        background-color: blue;
         
        text-indent: -9999px;
      }
     
      :is(li).slick-active {
        /* border: 1px solid #ED0E55;
        background-color: transparent; */
        background-image: url('../src/assets/svg/activeDot.svg');
        background-color: transparent;
        height: 24px !important;
        width: 24px !important;
    
      }
      :is(li).slick-active button {
        height: 14px;
        width: 14px;
        /* background-color: #ED0E55; */
        margin-top: 2.2px;
        margin-left: 2.4px;

      }
    }
        /* login hero section dots and arrows start */

    .heroSectionLogin .slider-controls{
      position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin-top: 0rem;
    width: 100%;
    display: flex;
    place-content: center;
    }
    .heroSectionLogin .slide-m-prev{
      margin-right: 4rem;
    }
    .heroSectionLogin .slick-dots{
      position: static;
      display: flex;
    }
    .heroSectionLogin .slide-m-next{
      margin-left: 4rem;
    }
        /* login hero section dots and arrows end */

    /* guuest hero section dots and arrows start */
    .heroSectionGuest .slider-controls{
      position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin-top: 0rem;
    width: 100%;
    display: flex;
    place-content: center;
    }
    .heroSectionGuest .slide-m-prev{
      margin-right: 4rem;
    }
    .heroSectionGuest .slick-dots{
      position: static;
      display: flex;
    }
    .heroSectionGuest .slide-m-next{
      margin-left: 4rem;
    }
        /* guuest hero section dots and arrows end */
    /* tournament section dots and arrows start */
    .tournament .tournamentslider-controls{
      position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin-top: 0rem;
    width: 100%;
    display: flex;
    place-content: center;
    }
    .tournament .tournamentslide-m-prev{
      margin-right: 4rem;
    }
    .tournament .slick-dots{
      position: static;
      display: flex;
    }
    .tournament .slick-dots li{
      margin: 0 1vw !important;
    }
    .tournament .tournamentslide-m-next{
      margin-left: 4rem;
    }
        /*liveStream section dots and arrows end */
    .liveStream .liveStreamslider-controls{
      position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin-top: 0rem;
    width: 100%;
    display: flex;
    place-content: center;
    }
    .liveStream .liveStreamslide-m-prev{
      margin-right: 4rem;
    }
    .liveStream .slick-dots{
      position: static;
      display: flex;
    }
    .liveStream .slick-dots li{
      margin: 0 1vw !important;
    }
    .liveStream .liveStreamslide-m-next{
      margin-left: 4rem;
    }
        /*liveStream section dots and arrows end */
        /*gameCategory section dots and arrows end */
    .gameCategory .gameCategoryslider-controls{
      position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin-top: 0rem;
    width: 100%;
    display: flex;
    place-content: center;
    }
    .gameCategory .gameCategoryslide-m-prev{
      margin-right: 4rem;
    }
    .gameCategory .slick-dots{
      position: static;
      display: flex;
    }
    .gameCategory .slick-dots li{
      margin: 0 1vw !important;
    }
    .gameCategory .gameCategoryslide-m-next{
      margin-left: 4rem;
    }
        /*gameCategory section dots and arrows end */
        /*topPlayer section dots and arrows end */
    .topPlayer .topPlayerslider-controls{
      position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    top: 15%;
    right: 65%;
    padding: 0;
    margin-top: 2rem;
    width: 100%;
    place-content: center;
    }
    .topPlayer .topPlayerslide-m-prev{
      margin-bottom: 2rem;
      transform: rotate(90deg);
    }
    .topPlayer .slick-dots{
      position: static;
      display: flex;
    }
    .topPlayer .slick-dots li{
      margin: 1vw 1vw !important;
      justify-self: center;
    }
    .topPlayer .slick-dots li.slick-active{
      background-image: url('../src/assets/svg/activeDotWhite.svg');

    }
    .topPlayer .topPlayerslide-m-next{
      margin-top: 2rem;
      transform: rotate(90deg);

    }
        /*topPlayer section dots and arrows end */

    .heroSectionMain .slick-dots{
      top: 106%;
    }
    .heroSectionMain .slick-dots li{
      margin: 0 1vw !important;
      /* height: 16px;
      width: 16px; */
      align-content: center;
    }
    .heroSectionMain .slick-dots li.slick-active button{
      margin-top: 0.2px;
        margin-left: 2px;
    }
    .heroSectionMain .customPrev{
      top: 107%;
      left: 38%;
    }
    .heroSectionMain .customNext{
      top: 107%;
      right: 38%;
    }
    .tournamentGamesMaincarousel .slick-dots{
      top: 106%;
    }
    .tournamentGamesMaincarousel .slick-dots li{
      margin: 0 1vw !important;
      /* height: 16px;
      width: 16px; */
      align-content: center;
    }
    .tournamentGamesMaincarousel .slick-dots li.slick-active button{
      margin-top: 0.2px;
        margin-left: 2px;
    }
    .tournamentGamesMaincarousel .customPrev{
      top: 107%;
      left: 35%;
    }
    .tournamentGamesMaincarousel .customNext{
      top: 107%;
      right: 35%;
    }
    .tournamentGamesMaincarousel  li.slick-active button {
      /* height: 14px;
      width: 14px;
      background-color: #ED0E55;
      margin-top: 2.1px !important;
      margin-left: 2.3px !important; */

    }
   .topPlayerMob li {
      margin: 0 0.25rem !important;
      background-color: #383c44;

    }
   .topPlayerMob li.slick-active {
      border: 1px solid white;
      background-color: transparent;

    }
    .topPlayerMob li.slick-active button {
      height: 14px;
      width: 14px;
      background-color: white;
      margin-top: 2.2px;
      margin-left: 2.4px;

    }
    .topPlayerMob  li.slick-active button {
      height: 14px;
      width: 14px;
      background-color: white;
      margin-top: 2.1px !important;
      margin-left: 2.3px !important;

    }
   .topPlayerWeb li {
      margin: 0.25rem 0.25rem !important;
      /* background-color: #383c44; */
      background-color: rgba(255, 255, 255, 0.4);
    }
   
   .topPlayerWeb .slick-dots {
      left: -60% ;
      display: grid ;
      bottom: 50% ;

    }
   .topPlayerWeb li.slick-active {
      /* border: 1px solid white; */
      background-color: transparent;
    /* width: 100% !important; */
  }
  .topPlayerWeb .slick-slide{
    width: 85% !important;
    margin-left: 5%;
    /* margin-top: -3rem; */
    position: relative;
    z-index: 0;
    border: none !important;
    /* opacity: 0.6; */
  }
  .topPlayerWeb .slick-slide .tapMain{
    border-radius: 0;
  }
 
  .topPlayerWeb .overlay:before {
    content: '';
    position: absolute;
    height: 22rem;
    width: 24rem;
    left: -11px;
    bottom: 267px;
    background: round;
    background-image: url('../src/assets/svg/pinkSmoke.svg');
    /* background:linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../src/assets/svg/pinkSmoke.svg'); */
  }
  .topPlayerWeb .slick-slide .tapMain{
    /* margin-top: -3rem; */
  }
  .topPlayerWeb .customNext{
    position: absolute;
    top: 53%;
    z-index: 11;
    width: 0rem;
    height: 1rem;
    left: -1.75rem;
    transform: rotate(90deg);
  }
  .topPlayerWeb .customPrev{
    position: absolute;
    top: 26%;
    z-index: 11;
    left: -2.3rem;
    transform: rotate(90deg);
  }
  .topPlayerWeb .slick-cloned{
   
    /* margin-top: 0rem; */
    }
    .topPlayerWeb .slick-current[fb-slick-index="0"] {
      /* margin-top: 3rem;  */
  }
  .topPlayerWeb .slick-current .tapMain{
    /* margin-top: 0rem; */
  }
  .topPlayerWeb .slick-current{
    
    width: 95% !important;
    margin-left: 0rem;
    margin-top: 0rem;
    z-index: 1;
    opacity: 1;

    /* margin-top: 0rem; */
    }
    .topPlayerWeb .slick-current .tapMain{
      border-radius: 0.125rem;
    }
  .topPlayerWeb .slick-current :before{
    
   content: none;

    /* margin-top: 0rem; */
    }
   .topPlayerWeb li.slick-slide {
      /* border: 1px solid white; */
      background-color: transparent;
      width: 90%;
    }

    .topPlayerWeb li.slick-active button {
      height: 14px;
      width: 14px;
      /* background-color: white; */
      margin-top: 2.2px;
      margin-left: 2.4px;

    }
    .topPlayerWeb  li.slick-active button {
      height: 14px;
      width: 14px;
      /* background-color: white; */
      margin-top: 2.1px !important;
      margin-left: 2.3px !important;

    }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.customPrev{
  position: absolute;
  top: 100.3%;
  z-index: 11;
  left: 41%;
}
.customPrev i{
  font-size: 1.5rem;
}
.customNext{
  position: absolute;
  top: 100.3%;
  z-index: 11;
  right: 41%;
}
.customNext i{
  font-size: 1.5rem;
}

/* slider */

.ngx-slider .ngx-slider-bar {
  background: #464c58 !important;
}
.ngx-slider .ngx-slider-selection {
  z-index: 2;
  background: #ED0E55 !important;
  border-radius: 2px;
}
.ngx-slider .ngx-slider-pointer{
  background-color: white !important;
  height: 19px !important;
  width: 19px !important;
  top: -8px !important;
}
.ngx-slider .ngx-slider-pointer:after {
 top: 5px !important;
 left: 5px !important;
 display: none;
}
.ant-picker-input>input{
  color: white !important;
}

ngx-guided-tour .tour-step .tour-block{
  background-color: #1A202C;
  color: white;
 box-shadow: none;
 border-radius: 2px;
}
ngx-guided-tour .tour-step .tour-buttons .next-button{
    background-color: #ED0E55;
    padding: 4px 17px !important;
    clip-path: polygon(12% 0, 100% 0, 100% 70%, 88.5% 100%, 0 100%, 0 31%);
    color: white;
}
ngx-guided-tour .tour-step .tour-buttons .next-button:hover{
    background-color: #ED0E55 !important;
    padding: 4px 17px !important;
    clip-path: polygon(12% 0, 100% 0, 100% 70%, 88.5% 100%, 0 100%, 0 31%);
    color: white;
}
ngx-guided-tour .tour-step .tour-buttons .back-button{
    color: #ED0E55 !important;
    font-weight: 500 !important;
    margin-top: 2px;
}
ngx-guided-tour .tour-step .tour-buttons button.skip-button {
  color: white;
  opacity: 0.4;
  font-size: 13px !important;
  font-weight: 500 !important;
}
ngx-guided-tour .tour-step.tour-right .tour-block{
  width: 20rem;
}
ngx-guided-tour .tour-step .tour-block{
  width: 20rem;
}
ngx-guided-tour .tour-step.tour-right .tour-arrow::before{
  border-right: 1rem solid #1A202C;
}
ngx-guided-tour .tour-step.tour-bottom .tour-arrow::before{
  border-bottom: 1rem solid #1A202C;
}
ngx-guided-tour .tour-step.tour-bottom-left .tour-arrow::before{
  border-bottom: 1rem solid #1A202C;
}
ngx-guided-tour .tour-step.tour-bottom-right .tour-arrow::before{
  border-bottom: 1rem solid #1A202C;
}
ngx-guided-tour .tour-step.tour-top .tour-arrow::before{
  border-top: 1rem solid #1A202C;
}
ngx-guided-tour .tour-step h3.tour-title{
  font-family: Anton;
  letter-spacing: 0.6px;
  font-weight: 100 !important;
}
ngx-guided-tour .tour-step .tour-content{
  opacity: 0.8;
  font-weight: 100;
}

 .ant-picker-panel-container{
  background: rgba(36, 40, 48, 1) !important;
}
.ant-picker-content th{
  color: white !important;
}
.ant-picker-header{
  color: white !important;
}
.ant-picker-cell{
  color: rgb(94, 93, 93) !important;
}
.ant-picker-cell-in-view{
  color: white !important;
}
.ant-picker-header button{
  color: white !important;
}
.ant-picker-cell-in-view:hover{
  background-color: rgba(36, 40, 48, 1) !important;
}
.ant-picker-range-arrow::before{
  background-color: rgba(36, 40, 48, 1) !important;

}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner{
  background-color: #ED0E55 !important;
}
.ant-picker-range .ant-picker-active-bar{
  background-color: transparent !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range{position:relative}.ant-picker-cell-in-view.ant-picker-cell-in-range::before{
  background-color: grey;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,.ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,.ant-picker-panel>:not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before{
  background-color: grey;

}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  background-color: #ED0E55 !important;

}
.ant-picker-focused{
 box-shadow: none;
}
.ant-dropdown-arrow,.ant-picker-range-arrow{
  height:11.31px;
  background:linear-gradient(135deg,transparent 40%,#292828 40%) !important;
  border-radius:0 0 2px
}
::ng-deep .ant-calendar {
  background-color: #20242c !important;
}

input:focus{
  --tw-ring-inset: none !important;

}
.viewLiveStreamMain iframe {
  width: 100%;
height: 315px !important;
}
.viewLiveStreamMain .liveUrlThumbnail {
  width: 100%;
  height: 315px !important;
}

.dynamicRules ul{
  list-style: disc;
  margin-left: 1rem;
}